import React, { useState, useContext, useEffect } from "react";
import { AppDataContext } from "@src/components/AppDataContext";
import HeroSection from "@src/components/partials/mentorpdkit/HeroSection";
import CardSection from "@src/components/partials/mentorpdkit/CardSection";
import ShareSection from "@src/components/partials/mentorpdkit/ShareSection";
import ThankYouSection from "@src/components/partials/mentorpdkit/ThankYouSection";
import { graphql, useStaticQuery } from "gatsby";
import { logEvent } from "@src/utils/logClient";
import starDojo from "@src/assets/images/mentors-pd-kit/dojo-star.svg";
import mentorSeal from "@src/assets/images/mentors-pd-kit/classdojo-mentor-badge.svg";
import bgCurve from "@src/assets/images/mentors-pd-kit/bg-curve-swish.svg";
import SEO from "@src/components/SEO";
import EmailModal from "@src/components/partials/mentorpdkit/EmailModal";

const MentorPdPage = () => {
  const data = useStaticQuery(graphql`
    {
      directus {
        page_mentor_pd_kit_2024 {
          main_download_url
          a_la_carte {
            card_title
            text
            image {
              filename_disk
            }
            logging_id
            download_url
          }
          button_text
          box_1_cta
          box_2_cta
          box_2_image {
            filename_disk
          }
          thank_you_item_1_image {
            filename_disk
          }
          thank_you_item_2_image {
            filename_disk
          }
          thank_you_item_3_image {
            filename_disk
          }
          thank_you_item_1_link
          thank_you_item_2_link
          thank_you_item_3_link
        }
      }
    }
  `);
  const appData = useContext(AppDataContext);

  const {
    directus: {
      page_mentor_pd_kit_2024: {
        main_download_url,
        a_la_carte,
        box_1_cta,
        box_2_cta,
        thank_you_item_1_image,
        thank_you_item_2_image,
        thank_you_item_3_image,
        thank_you_item_1_link,
        thank_you_item_2_link,
        thank_you_item_3_link,
      },
    },
  } = data;

  const [emailField, setEmailField] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [eventId, setEventId] = useState("");

  const closeEmailModal = () => setShowModal(false);
  const openEmailModal = (url: string, id: string) => {
    setShowModal(true);
    logEvent({
      eventName: `web.external.mentor-pd-2024.open_modal.${id}`,
      eventValue: window.location.href,
    });
    setDownloadUrl(url);
    setEventId(id);
  };

  const logDownloadEvent = (id: string) => {
    logEvent({
      eventName: `web.external.mentor-pd-2024.download_file.${id}`,
      eventValue: window.location.href,
      ...(!appData.data.userData && { metadata: emailField }),
    });
  };

  useEffect(() => {
    logEvent({
      eventName: `web.external.mentor-pd-2024.load_page`,
      eventValue: window.location.href,
    });
  }, []);

  useEffect(() => {
    if (!appData.data.loadingSession && appData.data.userData) {
      setEmailField(appData.data.userData.emailAddress);
    }
  }, [appData.data.loadingSession, appData.data.userData]);

  const fileUrlPrefix = "https://static.classdojo.com/uploads/";

  const isLoggedIn = emailField.length > 0;

  const emailModalProps = {
    closeModal: closeEmailModal,
    handleDownloadClick: logDownloadEvent,
    image: mentorSeal,
    inputValue: emailField,
    handleInputChange: setEmailField,
    eventId,
    downloadUrl,
  };

  const heroSectionProps = {
    heading: "directus.page_mentor_pd_kit_2024.hero_heading",
    tagline: "directus.page_mentor_pd_kit_2024.hero_subheading",
    downloadHeading: "directus.page_mentor_pd_kit_2024.hero_box_heading",
    downloadDescription: "directus.page_mentor_pd_kit_2024.hero_box_subheading",
    downloadButtonText: "directus.page_mentor_pd_kit_2024.hero_button",
    downloadButtonUrl: main_download_url,
    images: {
      bgCurve,
      starDojo,
      mentorSeal,
      certificate: fileUrlPrefix + "b54e3bab-9992-4950-8ec9-e87b9f663726.png",
      leftSide: fileUrlPrefix + "5634b74a-82f0-4d11-afd8-55bfdec89dd6.png",
      presentation: fileUrlPrefix + "c1ed40c0-8a33-4bda-9a82-38abdbef883d.png",
    },
    isLoggedIn,
    downloadAction: logDownloadEvent,
    setEmailAction: openEmailModal,
  };

  const cardSectionProps = {
    heading: "directus.page_mentor_pd_kit_2024.cards_section_heading",
    subheading: "directus.page_mentor_pd_kit_2024.cards_section_subheading",
    isLoggedIn,
    cards: a_la_carte.map((card) => ({
      image: card.image ? fileUrlPrefix + card.image.filename_disk : null,
      title: card.card_title,
      description: card.text,
      downloadUrl: card.download_url,
      eventId: card.logging_id,
    })),
    downloadAction: logDownloadEvent,
    setEmailAction: openEmailModal,
  };

  const shareSectionProps = {
    heading: "directus.page_mentor_pd_kit_2024.share_heading",
    cards: [
      {
        heading: "directus.page_mentor_pd_kit_2024.box_1_heading",
        text: "directus.page_mentor_pd_kit_2024.box_1_text",
        youtubeId: box_1_cta,
        href: "/dojoglow/?utm_source=mentor_bts_2024_LP&utm_medium=learn_more_cta&utm_campaign=mentorreferralphase2",
      },
      {
        heading: "directus.page_mentor_pd_kit_2024.box_2_heading",
        text: "directus.page_mentor_pd_kit_2024.box_2_text",
        youtubeId: box_2_cta,
        href: "/sayhello/?utm_source=mentor_bts_2024_LP&utm_medium=learn_more_cta&utm_campaign=mentorreferralphase2",
      },
    ],
  };

  const thankYouSectionProps = {
    title: "directus.page_mentor_pd_kit_2024.thank_you_heading",
    description: "directus.page_mentor_pd_kit_2024.thank_you_text",
    tiles: [
      {
        title: "directus.page_mentor_pd_kit_2024.thank_you_item_1_text",
        image: fileUrlPrefix + thank_you_item_1_image.filename_disk,
        url: thank_you_item_1_link,
      },
      {
        title: "directus.page_mentor_pd_kit_2024.thank_you_item_2_text",
        image: fileUrlPrefix + thank_you_item_2_image.filename_disk,
        url: thank_you_item_2_link,
      },
      {
        title: "directus.page_mentor_pd_kit_2024.thank_you_item_3_text",
        image: fileUrlPrefix + thank_you_item_3_image.filename_disk,
        url: thank_you_item_3_link,
      },
    ],
  };

  return (
    <>
      <SEO
        title="directus.page_mentor_pd_kit_2024.seo_title"
        description="directus.page_mentor_pd_kit_2024.seo_description"
        image="https://static.classdojo.com/uploads/f9168a2f-0647-4c5e-a400-24523991c89f.png"
      />
      {showModal && <EmailModal {...emailModalProps} />}
      <HeroSection {...heroSectionProps} />
      <CardSection {...cardSectionProps} />
      <ShareSection {...shareSectionProps} />
      <ThankYouSection {...thankYouSectionProps} />
    </>
  );
};

export default MentorPdPage;
